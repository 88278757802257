<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a @click="goBack()" class="nav__btn">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__btn">
					<i class="icon icon-location"></i>
				</span>
				<router-link to="/layers/location" custom v-slot="{ navigate }" >
					<span class="nav__text" v-if="selectedLocation !== null" @click="navigate">
						{{selectedLocation.area_nm2}}
					</span>
				</router-link>
			</p>
			<a class="nav__btn" @click="handleOpenMapRooms">
				<i class="icon icon-map"></i>
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub inn">
			<!-- category -->
			<section class="listsearch">
				<div class="listsearch__wrap">
					<div class="listsearch__inner">
						<router-link to="/layers/date" custom v-slot="{ navigate }" >
							<div class="listsearch__box" @click="navigate">
								<span class="listsearch__icon">
									<i class="icon icon-date"></i>
								</span>
								<input v-if="dateRangeText !== null" type="text" class="listsearch__ipt" :value="dateRangeText" readonly> 
							</div>
						</router-link>
					</div>
					<div class="listsearch__inner">
						<router-link to="/layers/member" custom v-slot="{ navigate }" >
							<div class="listsearch__box" @click="navigate">
								<span class="listsearch__icon">
									<i class="icon icon-member"></i>
								</span>
								<input v-if="selectedMember !== null" type="text" class="listsearch__ipt" :value="`성인 ${selectedMember.adultCount}명, 아동 ${selectedMember.childCount}명`" readonly>
							</div>
						</router-link>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="box">
					<div class="menus">
						<div class="menus__wrap">
							<!-- [D] 활성화된 지역에 active 클래스 추가 -->
							<!-- [D] 나만의 숙소찾기 시 노출되는 숙소리스트는 숙소유형 탭이 추가 -->
							<div class="menus__box">
								<div class="menus__text" :class="{active: hotelType === 'MO'}" @click="setHotelType('MO')">{{t('10021')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'HO'}" @click="setHotelType('HO')">{{t('10022')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'PS'}" @click="setHotelType('PS')">{{t('10024')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'GH'}" @click="setHotelType('GH')">{{t('10563')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'GL'}" @click="setHotelType('GL')">{{t('10564')}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="listbox">
					<div class="box">
						<div class="list__meta">
							<div class="list__check">
								<div class="checkbox">
									<label>
										<input type="checkbox" class="chk blind" v-model="cp_yn">
										<span class="checkbox__bg"></span>
										{{t('10008')}}
									</label>
								</div>
							</div>
							<div class="list__option">
								<button type="button" @click="handleOpenSorting">
									<i class="icon icon-align">
										{{t('10009')}}
									</i>
								</button>
								<button type="button" @click="handleOpenFilter">
									<i class="icon icon-filter">
										{{t('10017')}}
									</i>
								</button>
							</div>
						</div>
					</div>
					<!-- [REST] 숙소 리스트 -->
					<rooms-thumbnail-list v-if="hotelType === 'HO'" :list="mrm01011" :sortMode="sortMode"/>
					<rooms-list v-else :list="mrm01011" :sortMode="sortMode"/>
					<div class="row" v-if="mrm01011.length > 0">
						<div class="eoc">
						<span>
							{{t('10560')}}
						</span>
						</div>
					</div>
					<div class="row" v-else>
						<div class="centered" style="height:calc(100vh - 280px)">
							<div class="centered__wrap">
								<div class="textbox">
									<div class="textbox__icon">
										<img src="@/assets/images/img_warning.png" alt="숙소 없음">
									</div>
									<h3 class="textbox__title">
										{{t('10562')}}
									</h3>
									<p class="textbox__description">
										{{t('10561')}}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<RoomSorting v-if="openSorting" :handleClose="handleCloseSorting" :mode="sortMode" :changeMode="changeSortMode"/>
	<RoomFilter v-if="openFilter" :handleClose="handleCloseFilter" :handleSubmit="handleSubmitFilter"/>
	<MapRooms v-if="openMapRooms" :handleClose="handleCloseMapRooms" :list="mrm01011" />
	<TheNavigation></TheNavigation>
</template>

<script >
import { useStore } from 'vuex'
import { computed, onMounted, ref, reactive, watch } from '@vue/runtime-core'
import RoomsList from '@/components/RoomsList';
import RoomsThumbnailList from '@/components/RoomsThumbnailList';
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs';
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");


export default {
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		
		const hotelType = ref(route.query.hotel_type ?? "MO");
		const cp_yn = ref(false);
		const openSorting = ref(false);
		const sortMode = ref(0);
		const openFilter = ref(false);
		const openMapRooms = ref(false);
		const watchCall = ref(false);

		let filterData = reactive({convs:undefined,price:[undefined, undefined]});

		const userData = computed(() => store.state.userData);

		onMounted(() => {
			//location 설정
			if(store.state.mhm01.selectedLocation !== null) {
				router.replace({
					path: '/rooms/list',
					query: {
						location: JSON.stringify(store.state.mhm01.selectedLocation),
						hotel_type: route.query.hotel_type ?? undefined,
					},
				});
			} else if(route.query.location) {
				store.commit("mhm01/SET_SELECT_LOCATION", {location: JSON.parse(route.query.location)});
			} else {
				router.replace({
					path: '/layers/location',
					query: {
						callbackUrl: "/rooms/list" + (route.query.hotel_type ? `?hotel_type=${route.query.hotel_type}` : ""),
					},
				});
			}

			if(store.state.mhm01.selectedDateRange === null) {
				store.commit('mhm01/SET_SELECT_DATE', {dateRange: {start: dayjs().toISOString(), end: dayjs().add(1, "day").toISOString()}});
			}
			if(store.state.mhm01.selectedMember === null) {
				store.commit('mhm01/SET_MEMBER', {member: {adultCount: 2, childCount: 0}});
			}
		})
		
		watch(() => hotelType.value, (hotel_type) => {
			router.replace({
				path: '/rooms/list',
				query: {
					location: JSON.stringify(store.state.mhm01.selectedLocation),
					hotel_type,
				},
			});
		}) 

		store.watch(() => [
			watchCall.value,
			store.state.mhm01.selectedLocation,
			store.state.mhm01.selectedDateRange,
			store.state.mhm01.selectedMember,
			hotelType.value,
			cp_yn.value,
			filterData
		/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/
		], ([_ ,selectedLocation, selectedDateRange, selectedMember, hotel_type, cp_yn, filterData]) => {
			//console.log(selectedLocation, selectedDateRange, selectedMember, hotel_type, cp_yn, filterData);
			if(selectedLocation !== null && selectedDateRange !== null && selectedMember !== null) {
				store.dispatch("mrm01/fetchMrm01011", {
					sdate: dayjs(selectedDateRange.start).format("YYYYMMDD"),
					edate: dayjs(selectedDateRange.end).format("YYYYMMDD"),
					area_cd: selectedLocation.area_cd2,
					hotel_types: hotel_type,
					adult_cnt: selectedMember.adultCount,
					child_cnt: selectedMember.childCount,
					room_cnt: 1,
					cp_yn: cp_yn ? "Y" : "",
					mem_id: userData.value.mem_token,
					hotel_convs: filterData.convs === undefined ? undefined : filterData.convs.join(","),
					samt: filterData.price[0] !== undefined ? Number(filterData.price[0]) * 10000 : undefined,
					eamt: filterData.price[1] !== undefined ? filterData.price[1] > 50 ? undefined : Number(filterData.price[1]) * 10000 : undefined,
				})
			}
		}, {immediate: true})

		const mrm01011 = computed(() => store.state.mrm01.mrm01011);
		const goBack = () => router.go(-1);
		const { t }= useI18n() //번역필수 모듈

		
		return {
			mrm01011,
			goBack,
			sortMode,
			changeSortMode:(m) => {
				sortMode.value = m;
			},
			selectedLocation: computed(() => store.state.mhm01.selectedLocation),
			dateRangeText: computed(() =>
				store.state.mhm01.selectedDateRange !== null
				? dayjs(store.state.mhm01.selectedDateRange.start).format(
					"MM.DD(dd)"
					) +
					"~" +
					dayjs(store.state.mhm01.selectedDateRange.end).format("MM.DD(dd)")
				: null
			),
			selectedMember: computed(() => store.state.mhm01.selectedMember),
			cp_yn,
			hotelType,
			setHotelType: (hotel_type) => {
				hotelType.value = hotel_type;
			},
			openSorting,
			handleOpenSorting: () => {
				openSorting.value = true;
			},
			handleCloseSorting: () => {
				openSorting.value = false;
			},
			openFilter,
			handleOpenFilter: () => {
				openFilter.value = true;
			},
			handleCloseFilter: () => {
				openFilter.value = false;
			},
			openMapRooms,
			handleOpenMapRooms: () => {
				openMapRooms.value = true;
			},
			handleCloseMapRooms: () => {
				openMapRooms.value = false;
			},
			handleSubmitFilter: (convs, price) => {
				openFilter.value = false;
				filterData = reactive({
					convs,
					price,
				});
				watchCall.value = !watchCall.value;
			},
			t,
			i18n
		}
	},
	components: {
		RoomsList,
		RoomsThumbnailList
	}
}

</script>
